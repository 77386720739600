import { useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "src/components/Card";
import Chat from "src/components/chat/Chat";
import ReviewForm from "src/components/forms/ReviewForm";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import DownloadAppBox from "src/components/promotion/share/DownloadAppBox";
import RegistrationMatch from "src/components/tasks/panes/registration/student/RegistrationMatch";
import useTask from "src/hooks/api/services/tasks/task/useTask";
import useGet from "src/hooks/rest/useGet";
import { useParseBackUrl } from "src/hooks/useBackUrl";
import NotFoundView from "src/views/auth/NotFoundView";

const TaskDetailView = ({ t }: Translation) => {
	const back = useParseBackUrl();
	const location = useLocation();
	const { registration: registrationParam, extra, id } = useParams() as any;
	const { task, status } = useTask({ id });
	const navigate = useNavigate();
	const registrationHid = registrationParam || task?.links?.registration;
	const [{ data: registration }, loading, { fetchGet: refetch }] = useGet(
		registrationHid
			? `/client/registrations/${registrationHid}?with[]=score`
			: undefined
	);

	const getBreadCrumb = () => {
		if (back === "/tasks/registrations") {
			return [
				{
					label: t("breadcrumb.registrations"),
					to: back,
				},
				{
					label: task?.title,
					to: location.pathname,
				},
			];
		}
		return undefined;
	};

	if (!registrationHid) {
		return <NotFoundView />;
	}

	if (!registration?.id && !loading) {
		return (
			<Layout
				title={task?.title}
				loading={status === "loading" || loading}
			>
				<div className="flex flex-col justify-center items-center flex-1">
					{!["idle", "error"].includes(status) ? (
						<span className="base-loader"></span>
					) : (
						<strong>{t("empty.title")}</strong>
					)}
				</div>
			</Layout>
		);
	}

	return (
		<Layout
			title={task?.title}
			loading={status === "loading" || loading}
			backUrl={back || "/tasks"}
			breadcrumb={getBreadCrumb()}
			container="xxl"
		>
			{extra === "chat" ? (
				<>
					{registration?.id && (
						<Chat
							options={{
								type: "task",
								room: `room-${registration.id}`,
								backUrl: `/tasks/${task?.hid}/show/${registration.hid}`,
							}}
							data={{
								task,
								registration,
							}}
							loading={false}
						/>
					)}
				</>
			) : extra === "accept" ? (
				<div className="flex flex-col gap-8">
					{registration?.is_accepted ? (
						<ReviewForm
							taskId={task.id}
							userId={registration?.user?.id}
							companyId={task?.company?.id}
							onDone={() => {
								refetch();
								navigate(
									`/tasks/${task?.hid}/show/${registration?.hid}`
								);
							}}
							variant={extra === "cancel" ? "cancel" : undefined}
							onCancel={() => {
								refetch();
								navigate(
									`/tasks/${task?.hid}/show/${registration?.hid}`
								);
							}}
						/>
					) : (
						<Card title={t("not-selected.title")}>
							<p>{t("not-selected.description")}</p>
						</Card>
					)}
				</div>
			) : (
				<Card>
					<RegistrationMatch {...{ registration }} />
				</Card>
			)}
			{/* <pre>{JSON.stringify(registration, null, 4)}</pre> */}
			<div className="flex justify-center items-center mt-8">
				<DownloadAppBox size="medium" />
			</div>
		</Layout>
	);
};

TaskDetailView.locale = {
	nl: {
		breadcrumb: {
			registrations: "Aanmeldingen",
		},
		empty: {
			title: "Geen opdracht gevonden",
		},
		"not-selected": {
			title: "Je bent nog niet gekozen",
			description: "Helaas ben je nog niet gekozen voor de opdracht.",
		},
	},
	en: {
		breadcrumb: {
			registrations: "Registrations",
		},
		empty: {
			title: "No task found",
		},
		"not-selected": {
			title: "You have not been chosen yet",
			description:
				"Unfortunately you have not yet been chosen for the task.",
		},
	},
};

export default withTranslation(TaskDetailView);
